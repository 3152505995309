import React from 'react';


function Goals(props) {
  return (
    <section>
        
        <div className="mainlinktitle">
        <h1 id="about">TESTIMONIALS</h1>
        </div>
        
        
        <div className="rightsectiondarkgoals">
          <br/>
        <h3>"Summer did an amazing job with my hair. She is easily the best hairdresser I've ever met, 11/10".
          <br/>
          - Talon
        </h3>
        </div>
  
      </section>
  );

}

export default Goals;